import * as React from 'react';
import { Portal } from 'react-portal';
import SocialSharingModal from './SocialSharingModal';
import SocialSharingModalContext from './SocialSharingModalContext';
import { ModalProps } from './Types';

export default function SocialSharingModalProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [uri, setUri] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');

  const showSocialSharingModal = React.useCallback((modalProps: ModalProps) => {
    setIsOpen(true);
    setUri(modalProps.uri);
    setTitle(modalProps.title);
  }, []);

  const hideSocialSharingModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const socialSharingModalContextValue = React.useMemo(
    () => ({
      showSocialSharingModal,
      hideSocialSharingModal,
    }),
    [showSocialSharingModal, hideSocialSharingModal],
  );

  return (
    <SocialSharingModalContext.Provider value={socialSharingModalContextValue}>
      {children}
      <Portal>
        <SocialSharingModal
          open={isOpen}
          onClose={hideSocialSharingModal}
          uri={uri}
          title={title}
        />
      </Portal>
    </SocialSharingModalContext.Provider>
  );
}
