/**
 * Site wide layout persisting between page changes
 */
import * as React from 'react';
import 'modern-normalize';
import './global.css';
import { PageProps } from 'gatsby';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import useAudioPlayer from '../AudioPlayer/useAudioPlayer';
import AudioPlayerContext from '../AudioPlayer/AudioPlayerContext';
import FirebaseProvider from '../FirebaseProvider';
import FinishSignInWithEmail from '../FinishSignInWithEmail';
import ToastProvider from '../ToastProvider';
import StandaloneAudioPlayerContext from '../AudioPlayer/StandaloneAudioPlayerContext';
import StoreProvider from '../StoreProvider';
import SocialSharingModalProvider from '../SocialSharingModalProvider';
import TimedMembershipPopup from '../TimedMembershipPopup';
import MembershipPopupProvider from '../MembershipPopupProvider';
import AnnouncementBar from '../AnnouncementBar';
import SearchContextProvider from '../PageLayout/SearchContextProvider';
import { isLiveEnv } from '../../../config';

const isBrowser = typeof window !== undefined;

const AudioPlayer = React.lazy(() => import('../AudioPlayer/AudioPlayer'));

function InnerGlobalLayout({ children, location }: PageProps) {
  const [pathname, setPathname] = React.useState<string | null>(null);
  const audioPlayer = useAudioPlayer();

  React.useEffect(() => {
    if (isBrowser) {
      setPathname(location.pathname);
    }
  }, [location.pathname]);

  if (location.pathname.startsWith('/twitter-player/')) {
    return <div>{children}</div>;
  }

  return (
    <AudioPlayerContext.Provider value={audioPlayer}>
      {isLiveEnv && (
        <Helmet>
          {/* Add illow.io cookie banner */}
          <script src="https://platform.illow.io/banner.js?siteId=cd6f77c6-422d-4bfa-9e4f-67208097132a" />
        </Helmet>
      )}
      <ToastProvider>
        <SocialSharingModalProvider>
          <StoreProvider>
            <SearchContextProvider>
              <AnnouncementBar pathname={pathname} />
              {children}
            </SearchContextProvider>
          </StoreProvider>
        </SocialSharingModalProvider>
      </ToastProvider>
      <React.Suspense>
        <AudioPlayer />
      </React.Suspense>
      <FinishSignInWithEmail />
      {/* Make sure an error in TimedMembershipPopup doesn't break the app */}
      <ErrorBoundary fallback={null}>
        <TimedMembershipPopup />
      </ErrorBoundary>
    </AudioPlayerContext.Provider>
  );
}

export default function GlobalLayout(pageProps: PageProps) {
  const [isStandaloneAudioPlayerPlaying, setIsStandaloneAudioPlayerPlaying] = React.useState(false);
  const [currentlyPlayingItem, setCurrentlyPlayingItem] = React.useState('');
  const currentlyPlayingItemValue = React.useMemo(
    () => ({
      isStandaloneAudioPlayerPlaying,
      setIsStandaloneAudioPlayerPlaying,
      currentlyPlayingItem,
      setCurrentlyPlayingItem,
    }),
    [
      isStandaloneAudioPlayerPlaying,
      setIsStandaloneAudioPlayerPlaying,
      currentlyPlayingItem,
      setCurrentlyPlayingItem,
    ],
  );
  return (
    <StandaloneAudioPlayerContext.Provider value={currentlyPlayingItemValue}>
      <FirebaseProvider>
        <MembershipPopupProvider>
          {InnerGlobalLayout !== undefined && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <InnerGlobalLayout {...pageProps} />
          )}
        </MembershipPopupProvider>
      </FirebaseProvider>
    </StandaloneAudioPlayerContext.Provider>
  );
}
