import * as React from 'react';
import styled from 'styled-components';
import {
  faXTwitter,
  faFacebook,
  faLinkedinIn,
  faWhatsapp,
  faReddit,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { cssClampValue } from '../utils/common';
import { siteUrl } from '../../config';

const SocialLinkWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
  overflow-y: scroll;
`;

const SocialLink = styled.a<{ $bg_color: string }>`
  display: flex;
  width: ${cssClampValue(35, 50)};
  height: ${cssClampValue(35, 50)};
  padding: 0;
  margin-right: ${cssClampValue(8, 12)};
  color: #fff;
  font-size: ${cssClampValue(14, 21)};
  background-color: ${({ $bg_color }) => $bg_color};
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export default function SocialSharingLinks({ uri, title }: { uri: string; title: string }) {
  const encodedTitle = encodeURIComponent(title);
  const encodedTitleWithSiteName = encodeURIComponent(`"${title}" from Earth.fm`);
  const encodedUrl = encodeURIComponent(`${siteUrl}${uri}`);
  const shareUrl = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${encodedTitleWithSiteName}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&via=earth_fm_live`,
    linkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
    whatsapp: `whatsapp://send?text=${encodedTitle}%20-%20${encodedUrl}`,
    reddit: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitleWithSiteName}`,
    email: `mailto:?subject=${encodedTitleWithSiteName}&body=${encodedUrl}`,
    pinterest: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&description=${encodedTitleWithSiteName}`,
  };

  if (encodedUrl === '') {
    return null;
  }

  return (
    <SocialLinkWrapper>
      <SocialLink
        href={shareUrl.facebook}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#1877f2"
      >
        <FontAwesomeIcon icon={faFacebook} />
        <span className="screen-reader-text">Share on facebook</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.twitter}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#000000"
      >
        <FontAwesomeIcon icon={faXTwitter} />
        <span className="screen-reader-text">Share on X (twitter)</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.linkedIn}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#0a66c2"
      >
        <FontAwesomeIcon icon={faLinkedinIn} />
        <span className="screen-reader-text">Share on LinkedIn</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.whatsapp}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#25d366"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
        <span className="screen-reader-text">Share on Whatsapp</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.reddit}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#ff4500"
      >
        <FontAwesomeIcon icon={faReddit} />
        <span className="screen-reader-text">Share on Reddit</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.email}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#1da1f2"
      >
        <FontAwesomeIcon icon={faEnvelope} />
        <span className="screen-reader-text">Share via email</span>
      </SocialLink>
      <SocialLink
        href={shareUrl.pinterest}
        target="_blank"
        rel="noopener noreferrer"
        $bg_color="#bd081c"
      >
        <FontAwesomeIcon icon={faPinterest} />
        <span className="screen-reader-text">Share on Pinterest</span>
      </SocialLink>
    </SocialLinkWrapper>
  );
}
