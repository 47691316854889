import { Timestamp } from 'firebase/firestore';
import { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { MutableRefObject } from 'react';

export interface RecordingMarkerData {
  id: number;
  title: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  duration: number;
  recordist: number[] | null;
  habitat: number | null;
  mood: number | null;
  region: string | null;
  predominantSound: number | null;
  isLivestream: boolean;
  modified: string | null;
}

export interface RecordistType {
  id: number;
  graphqlId?: string;
  title: string | null;
  uri: string | null;
}

export interface RecordingPreviewData {
  id: number;
  title: string;
  userFriendlyTitle: string | null;
  uri: string;
  audio: string;
  isLivestream: boolean;
  duration: number;
  recordists: RecordistType[];
  featuredImage: ImageDataLike | null;
  location: string | null;
  habitat: string | null;
  mood: string | null;
  predominantSound: string | null;
}

export interface PlaybackItem {
  id: number;
  title: string;
  userFriendlyTitle: string | null;
  uri: string;
  sourceUrl: string;
  coverImg: ImageDataLike | string | null;
  duration: number;
  isLivestream: boolean;
  recordists: RecordistType[];
  recordist?: string; // Legacy field
  recordistUri?: string | null; // Legacy field
}

export type PlaylistItem = PlaybackItem & {
  habitat: string | null;
  location: string | null;
};

export interface Podcast {
  id: number;
  title: string;
  excerpt: string;
  uri: string;
  featuredImage: ImageDataLike;
  featuredImageStyle: string | null;
}

export interface Playlist {
  id: string;
  title: string;
  userFriendlyTitle: string | null;
  subtitle: string | null;
  uri: string;
  thumbnail: IGatsbyImageData | string | null;
  owner: {
    id: string;
    name: string;
  };
  items: PlaylistItem[];
  public: boolean;
  playlistName: string | null;
  isTrendingRecordingsPlaylist?: boolean;
}

export interface MemberPlaylist {
  id: string;
  name: string;
  description?: string | null;
  thumbnail?: string | null;
  public: boolean;
  owner: string;
  items?: number[];
}

export type AudioPlayerState = 'buffering' | 'playing' | 'paused' | 'idle' | 'error';

export interface AudioPlayerInstance {
  audioRef: MutableRefObject<HTMLAudioElement | undefined> | null;
  state: AudioPlayerState;
  playlist: Playlist | null;
  currentItem: PlaybackItem | null;
  hasNext: boolean;
  hasPrev: boolean;
  currentTime: number;
  isShuffling: boolean;
  isLooping: boolean;
  startPlayback: (params: {
    item: PlaybackItem;
    playlist?: Playlist;
    eventSource?: string;
  }) => void;
  loadPlayback: (params: { item: PlaybackItem; eventSource?: string }) => void;
  play: () => void;
  pause: () => void;
  reset: () => void;
  seekTo: (time: number) => void;
  playPrevious: () => void;
  playNext: () => void;
  toggleIsShuffling: () => void;
  toggleIsLooping: () => void;
}

export interface StandaloneAudioPlayerType {
  isStandaloneAudioPlayerPlaying: boolean;
  setIsStandaloneAudioPlayerPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  currentlyPlayingItem: string;
  setCurrentlyPlayingItem: React.Dispatch<React.SetStateAction<string>>;
}

export interface FeaturedItemData {
  label: string;
  title: string | null;
  uri: string | null;
  description: string | null;
  date: string | null;
  featuredImage: ImageDataLike | null;
  featuredImageStyle: string | null;
}

export enum Membership {
  Individual = 'individual',
  Family = 'family',
  None = 'none',
  // Below are the legacy membership plans
  // keeping it for the existing members
  Friend = 'friend',
  Supporter = 'supporter',
  Partner = 'partner',
}

export interface MemberProfileData {
  id: string; // firebase database id, same as firebase auth user.uid
  name: string;
  photoURL: string;
  memberSince: Timestamp;
}

export type LatestMember = Omit<MemberProfileData, 'memberSince'> & {
  memberSince: Date;
};

export interface Favorites {
  recordings?: number[];
  playlists?: string[];
  podcasts?: number[];
}

export interface AlgoliaRecord {
  id: string;
  uri: string | null;
  title: string | null;
  date: string | null;
  dateTimestamp: number | null;
  meta: Record<string, string | number | null | string[]>;
  type: string;
  image: IGatsbyImageData | null;
  internal: {
    contentDigest: string;
  };
}

type RecordistSocialLinks = {
  twitter: string | null;
  youtube: string | null;
  soundCloud: string | null;
  spotify: string | null;
  bandcamp: string | null;
  xenoCanto: string | null;
};

export interface Recordist {
  id: string;
  uri: string;
  title: string;
  profileImage: ImageDataLike | null;
  backgroundImage: ImageDataLike | null;
  websiteUrl: string | null;
  date: string | null;
  socialLinks: RecordistSocialLinks | null;
  about: string | null;
}

export interface PlaylistData {
  id: string;
  title: string;
  userFriendlyTitle: string | null;
  playlistName: string | null;
  subtitle: string | null;
  uri: string;
  thumbnail: IGatsbyImageData | string | null;
}

export interface Recording {
  id: number | null;
  uri: string | null;
  title: string | null;
  recordingSettings: {
    recordists: Readonly<(RecordistType | null)[]> | null;
    audioUrl: string | null;
    audio: { mediaDetails: { length: number | null } | null } | null;
    region: string | null;
    habitat: { name: string | null } | null;
    isLivestream: boolean | null;
    userFriendlyTitle: string | null;
  } | null;
  featuredImage: {
    node: {
      localFile: { childImageSharp: { gatsbyImageData: IGatsbyImageData | null } | null } | null;
    } | null;
  } | null;
  popularity: number | null;
}

export type ReadonlyRecording = Readonly<Recording[]>;

export type LatestItem = {
  [key: string]: any;
  date?: string | null;
  memberSince?: Date;
};

export type TestimonialType = {
  author: string;
  content: string;
  country: string | null;
  featuredImage: ImageDataLike | undefined;
};

export type ScholarshipId = 'scholarship50' | 'scholarship60' | 'scholarship70' | 'scholarship100';

export type PromoCodeProps = {
  id: string;
  code: string;
};

export type PageContext = {
  currentPage: number;
  numPages: number;
};

export interface PaginationProps {
  currentPage: number;
  numPages: number;
  pathPrefix?: string;
  onPageChange?: (page: number) => void;
  className?: string;
}
