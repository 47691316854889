import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import MembershipPopupContext from '../contexts/MembershipPopupContext';
import MembershipPopup from './MembershipPopup';
import FirebaseContext from '../contexts/FirebaseContext';
import { Membership } from '../types';
import { pages } from '../../config';
import { MembershipPopupType } from './MembershipPopup/MembershipPopup';

const popupLastShownAtKey = 'bannerLastShownAt';
const hidePopupAfterShownFor = 1000 * 60 * 60 * 24 * 7; // 7 days

export default function MembershipPopupProvider({ children }: { children: React.ReactNode }) {
  const [showPopup, setShowPopup] = React.useState<MembershipPopupType | false>(false);
  const { user, membership, loadingMembership } = React.useContext(FirebaseContext);

  const showMembershipModal = React.useCallback(
    (type: MembershipPopupType = 'default') => {
      // Don't bother if we are already showing the same or 'free-limit-reached' popup
      // This will not prevent converting default popup to 'free-limit-reached'
      if (showPopup === type || showPopup === 'free-limit-reached') {
        return;
      }

      if (type === 'default') {
        // Don't show the popup if the user has already seen the popup
        // Does not apply to the free-limit-reached popup
        const lastShownAt = Number(localStorage.getItem(popupLastShownAtKey) || 0);
        if (Date.now() - lastShownAt < hidePopupAfterShownFor) {
          return;
        }

        // Don't show default popup to logged in users (regardless of membership status)
        if (user) {
          return;
        }
      }

      // Don't show the popup if the user is already a member
      if (loadingMembership || membership !== Membership.None) {
        return;
      }

      // Don't show the popup if the user is on the membership page
      if (window.location.pathname === pages.becomeAMember && type === 'default') {
        return;
      }

      // Don't show the popup if the user is check sign in email page
      if (window.location.pathname === pages.checkSignInEmail && type === 'default') {
        return;
      }

      // Don't show the popup if the user is requesting scholarship
      if (window.location.pathname === pages.scholarship && type === 'default') {
        return;
      }

      setShowPopup(type);
    },
    [showPopup, loadingMembership, membership, user],
  );

  const handleClose = React.useCallback(() => {
    localStorage.setItem(popupLastShownAtKey, Date.now().toString());
    setShowPopup(false);
  }, []);

  const value = React.useMemo(
    () => ({
      isMembershipModalOpen: showPopup !== false,
      showMembershipModal,
    }),
    [showPopup, showMembershipModal],
  );

  return (
    <MembershipPopupContext.Provider value={value}>
      {children}
      {/* Make sure an error in MembershipModal doesn't break the app */}
      <ErrorBoundary fallback={null}>
        <MembershipPopup
          type={showPopup || 'default'}
          isOpen={showPopup !== false}
          onClose={handleClose}
        />
      </ErrorBoundary>
    </MembershipPopupContext.Provider>
  );
}
