import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'gatsby';
import { pages } from '../../config';
import { cssClampValue } from '../utils/common';
import useOnScreen from './hooks/useOnScreen';
import SearchContext from '../contexts/SearchContext';

const dropBar = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const marquee = keyframes`
  from {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Wrapper = styled.div<{
  $isHidden: boolean;
  $isLoading: boolean;
}>`
  --bar-height: 42px;
  display: grid;
  width: 100vw;
  height: ${({ $isHidden }) => ($isHidden ? '0px' : 'var(--bar-height)')};
  padding: 0;
  margin-bottom: ${({ $isHidden }) => ($isHidden ? '0px' : '10px')};
  color: var(--earth-fm--color--yellow);
  background-color: var(--earth-fm--color--green);
  position: relative;
  top: 0;
  overflow: hidden;
  grid-template-columns: 1fr;

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          transform: translateY(-100%);
          animation-play-state: paused;
        `
      : ''}

  ${({ $isLoading }) =>
    !$isLoading
      ? css`
          animation: ${dropBar} 0.8s ease-in-out forwards;
        `
      : css`
          transform: translateY(0);
        `};

  @media (min-width: 768px) {
    --bar-height: 48px;
  }
`;

const Contents = styled.div`
  width: 100%;
  height: var(--bar-height);
  font-size: ${cssClampValue(14, 18)};
  line-height: 2.25rem;
  pointer-events: none;
  overflow: hidden;
  position: relative;

  &:hover {
    * {
      animation-play-state: paused;
    }
  }
`;

const Marquee = styled.div`
  display: flex;
  height: 100%;
  gap: ${cssClampValue(50, 130)};
  position: absolute;
  z-index: 10;
  white-space: nowrap;
  align-items: center;
  animation: ${marquee} 60s linear infinite;
`;

const Content = styled.div`
  display: inline-block;
`;

type Props = {
  pathname: string | null;
};

export default function AnnouncementBar({ pathname }: Props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isHidden, setIsHidden] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const { isSearchOpen } = React.useContext(SearchContext);

  React.useEffect(() => {
    setIsLoading(false);

    if (isHidden && isLoading) {
      setIsLoading(true);
    }
  }, [isHidden, isLoading]);

  React.useEffect(() => {
    if (!pathname) {
      return;
    }

    if (
      [
        pages.app,
        pages.newsletter,
        pages.thankYou,
        pages.donationCompleted,
        pages.signIn,
        pages.signUp,
        pages.signInWithEmail,
        pages.checkSignInEmail,
        pages.memberProfileSetup,
        pages.memberLibrary,
      ].includes(pathname) ||
      isSearchOpen ||
      !isVisible
    ) {
      setIsLoading(true);
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [isSearchOpen, isVisible, pathname]);

  return (
    <Wrapper $isHidden={isHidden} ref={ref} $isLoading={isLoading} role="banner">
      <Link to={pages.app} title="Check out the new Earth.fm mobile app">
        <Contents>
          <Marquee>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
            <Content aria-hidden="true">Check out the new Earth.fm mobile app✨</Content>
          </Marquee>
        </Contents>
      </Link>
    </Wrapper>
  );
}
